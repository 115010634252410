import React from 'react';
import './header.css';
import people from "../../assets/people.png"
import medixtryai from "../../assets/medixtryai.png"

const Header = () => {
  return (
    <div className='gpt3__header section__padding' id="home">
      <div className='gpt3__header-content'>
        <h1 className="gradient__text">We build Health Tech solutions leveraging Artificial Intelligence</h1>
        <p>We enable your Digital Health Transformation by building high-end solutions leveraging the power of Data Analytics and Artificial Intelligence </p>
        <div className='gpt3__header-content__input'>
          {/* <input type="email" placeholder='Enter your email address'></input> */}
          {/* <button type="button">Get started</button> */}
        </div>
        {/* <div className='gpt3__header-content__people'>
          <img src={people} alt="people"/>
          <p>1,400 organizations use our system</p>
        </div> */}
      </div>
      <div className='gpt3__header-image'>
          <img src={medixtryai} alt="ai" />
      </div>
    </div>
  )
}

export default Header