import React, {useState} from 'react';

import {CTA, Brand, Navbar} from './components'
import { Footer, Blog, Possibility, Features, WhatGPT3, Header } from './containers';
import './App.css';

const App = () => {
  
  const [cursorX, setCursorX] = useState()
  const [cursorY, setCursorY] = useState()

  window.addEventListener('mousemove', (e) => {
    setCursorX(e.pageX)
    setCursorY(e.pageY)
  })

  return (
    <div className='App'>
      {/* <div className="cursor" style={{left:cursorX + "px",top: cursorY + "px"}}>
      </div> */}
      <div className='gradient__bg'>
          <Navbar />
          <Header />
      </div>
        <Brand />
        <WhatGPT3 />
        <Features />
        <Possibility />
        <CTA />
        <Blog />
        <Footer />
    </div>
    
  )
}

export default App