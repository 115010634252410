import React from 'react';
import './possibility.css';
import possibilityImage from '../../assets/possibility.png'

const Possibility = () => {
  return (
    <div className='gpt3__possibility section__padding' id="possibility">
      <div className='gpt3__possibility-image'>
        <img src={possibilityImage}  alt="possibilityImage"/>
      </div>
      <div className='gpt3__possibility-content'>
        <h4>Solving Healthcare's biggest problems with digital solutions</h4>
        <h1 className='gradient__text'>The possibilities are beyong your imagination</h1>
        <p>We are building Africa's Digital health hub through Artificial Intelligence, Medical Records and Apps that truly engage.</p>
        <h4>Request access to get started</h4>
      </div>
    </div>
  )
}

export default Possibility