import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="Who is Medixtry" text="Do you want the dignity of a lifetime healthcare that's personalized, efficient, and affordable? With our tech solutions, you can expect better care at a lower cost. Our digital solutions help you improve processes for better outcomes for all patients." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">Revolutionizing Digital Health</h1>
      <p>Explore our range of services</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Artificial Intelligence" text="Through Machine Learning, we deploy solutions that work to improve efficiency of care." />
      <Feature title="Healthcare Apps" text="From Electronic Medical Records to Patient Portals, we have it all covered" />
      <Feature title="Education" text="We don't just leave you hanging, our team of dedicated professionals are there to hold your hands" />
    </div>
  </div>
);

export default WhatGPT3;
