import React from 'react';
import { Article } from '../../components';
import { blog01, blog02, blog03, blog04, blog05 } from './imports'
import './blog.css';


const Blog = () => {
  return (
    <div className='gpt3__blog section__padding' id="blog">
      <div className='gpt3__blog-heading'>
        <h1 className='gradient__text'>Read more about what is happening in the world of Health Tech</h1>
      </div>
      <div className='gpt3__blog-container'>
        <div className='gpt3__blog-container_groupA'>
          <Article imgUrl={blog01} date="March 21, 2022" title="Innovative Digital Healthcare Solutions in Africa" link="https://www.frontiersin.org/articles/10.3389/fdgth.2022.854339/full" />
        </div>
        <div className='gpt3__blog-container_groupB'>
          <Article imgUrl={blog02} date="March 21, 2022" title="Role of Digital Health in Solving Africa's Health Challenges" link="https://www.undp.org/blog/what-role-can-digital-play-africas-health-challenges" />
          <Article imgUrl={blog03} date="March 21, 2022" title="National eHealth Strategy: 42 African Countries" link="https://www.ictworks.org/african-national-ehealth-strategy-policy/#.Y7A45XbMJD8" />
          <Article imgUrl={blog04} date="March 21, 2022" title="Maximizing Digital Health for Quality and Safety - WHO" link="https://www.afro.who.int/news/maximising-digital-health-technology-improve-quality-and-patient-safety-africa" />
          <Article imgUrl={blog05} date="March 21, 2022" title="Top 5 National Examples in Digital Health" link="https://medicalfuturist.com/top-5-national-examples-in-digital-health/" />
        </div>
      </div>
    </div>
  )
}

export default Blog