import React from 'react';
import './footer.css';
import medixtry from '../../assets/medixtry.svg'

const Footer = () => {
  return (
    <div className='gpt3__footer section__padding'>
      <div className='gpt3__footer-heading'>
        <h1 className='gradient__text'>Let's talk. Reach out today</h1>
      </div>
      <div className='gpt3__footer-btn'>
        <p>Request early access</p>
      </div>
      <div className='gpt3__footer-links'>
        <div className='gpt3__footer-links_logo'>
          <img src= {medixtry} alt="logo" />
          <p>Digital Healthcare Company</p>
        </div>
        <div className='gpt3__footer-links_div'>
          <h4>Links</h4>
          <p>Services</p>
          <p>Social Media</p>
          <p>Blog</p>
          <p>Contact</p>
        </div>
        <div className='gpt3__footer-links_div'>
          <h4>Company</h4>
          <p>Terms & Conditions</p>
          <p>Privacy Policy</p>
          <p>Contact</p>
        </div>
        <div className='gpt3__footer-links_div'>
          <h4>Get in touch</h4>
          <p>202, Fatimah Gold, Karu, Nasarawa State, Nigeria</p>
          <p>+2348140891218</p>
          <p>medixtry@gmail.com</p>
        </div>
      </div>
      <div className='gpt3__footer-copyright'>
        <p>© 2022 Medixtry. All rights reserved</p>
      </div>
    </div>
  )
}

export default Footer