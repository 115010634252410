import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Improving end distrusts instantly',
    text: 'Our digital healthcare solution is developed to solve the issues of healthcare. With some features, it highlights the weak parts of healthcare.',
  },
  {
    title: 'Improve quality and efficiency',
    text: 'The digital health solution is a digitized way to do your daily routine tasks and also improve the quality of your healthcare.',
  },
  {
    title: 'Understand and Perform',
    text: 'Our digital healthcare solution tries to make the medical field more easy and simple to understand by providing information about your health issues. We help you get treatment from doctors 24/7.',
  },
  {
    title: 'Patient and Healthcare Professionals first',
    text: 'Our Digital Healthcare solution will help to solve healthcare issues related to data collection, management and analysis.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">The Future is Now and You Just Need to Realize It. Step into Future Today. & Make it Happen.</h1>
      <p>Request Early Access to Get Started</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
